import React, { useEffect } from "react";
import LogoExtended from "../../Assets/images/Logos/logo_dataprove.png";
import LogIconOk from "../../Assets/images/Icons/outline-checked-simple.gif";

import "./styles.css";
import SweetAlert from "../../Utils/SweetAlert";

const SuccessPage = () => {
  useEffect(() => {
    (async () => {
      let token = await localStorage.getItem(
        "@data-prove-biometria:driverToken"
      );
      let driver = await localStorage.getItem("@data-prove-biometria:driverId");
      if (token && driver) {
        localStorage.removeItem("@data-prove-biometria:driverToken");
        localStorage.removeItem("@data-prove-biometria:driverId");
      } else {
        SweetAlert.small({
          type: "error",
          title: "Erro 5000 - Processo de validação de biometria inválida!",
          timer: 2000,
          cb: () => {
            localStorage.removeItem("@data-prove-biometria:driverToken");
            localStorage.removeItem("@data-prove-biometria:driverId");
            window.location.href = "https://dataprove.com.br/";
            return;
          },
        });
      }
    })();
  }, []);

  return (
    <div className="container-main-validacao">
      <div className="header-validacao">
        <img className="logo" src={LogoExtended} alt="" />
      </div>

      <div className="body-validacao">
        <div className="checkmarkLogo">
          <img className="logo" src={LogIconOk} alt="" />
        </div>
        <h5>VALIDAÇÃO DA BIOMETRIA CONCLUÍDA COM SUCESSO.</h5>
      </div>
    </div>
  );
};

export default SuccessPage;
