import React, { useRef, useState, useEffect } from 'react';
import RecordRTC from 'recordrtc';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import * as faceapi from 'face-api.js';
import logo from '../../Assets/images/Logos/logo_dataprove.png';
import axios from 'axios';
import './styles.css';

const FaceId = ({ setStep }) => {
  const videoRef = useRef(null);
  const recorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState('Acessando câmera...');
  const [hasError, setHasError] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [isFinished, setIsFinished] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const navigate = useNavigate();
  const faceDetectionInterval = useRef(null);
  const isFaceDetectedRef = useRef(false); // Substitui o estado por um ref

  const translateError = (error) => {
    const translations = {
      "No video file uploaded": "Nenhum arquivo de vídeo enviado.",
      "Uploaded file is not a valid video": "O arquivo enviado não é um vídeo válido.",
      "Video file exceeds the maximum allowed size of 5 MB": "O arquivo de vídeo excede o tamanho máximo permitido de 5 MB.",
      "No significant movement or too much detected in the video": "Sem movimento significativo ou muito movimento detectado no vídeo.",
      "No human face detected": "Nenhum rosto humano detectado.",
      "Internal Server Error": "Erro interno do servidor."
    };
    return translations[error] || "Erro desconhecido. Tente novamente.";
  };
  
  useEffect(() => {
    const loadModels = async () => {
      setMessage('Carregando modelos de detecção facial...');
      await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
    };

    const getVideoStream = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' },
        });
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
          setMessage('Câmera acessada com sucesso.');
          setTimeout(() => startCountdown(), 2000);
        };
      } catch (error) {
        handleCameraError(error);
      }
    };

    loadModels().then(getVideoStream);
    return () => stopVideoStream(); // Limpa recursos ao desmontar
  }, []);

  const handleCameraError = (error) => {
    const errorMessage = error.name === 'NotAllowedError' ?
      'Permissão de câmera negada. Por favor, habilite nas configurações do navegador.' :
      error.name === 'NotFoundError' ? 'Nenhuma câmera encontrada.' : 'Erro ao acessar a câmera. Tente novamente.';
    setMessage(errorMessage);
    setHasError(true);
    console.error('Erro ao acessar a câmera:', error);
  };

  const startCountdown = () => {
    if (isFinished) return;
    let timeLeft = 3;
    setCountdown(timeLeft);
    setMessage('Preparando gravação...');

    const timer = setInterval(() => {
      timeLeft -= 1;
      setCountdown(timeLeft);
      if (timeLeft <= 0) {
        clearInterval(timer);
        setCountdown(null);
        startRecording();
      }
    }, 1000);
  };

  const startRecording = () => {
    const stream = videoRef.current?.srcObject;
    if (!stream) return;
  
    const mimeType = MediaRecorder.isTypeSupported('video/mp4;codecs=avc1')
      ? 'video/mp4;codecs=avc1'
      : 'video/webm;codecs=vp8';
  
    recorderRef.current = new RecordRTC(stream, {
      type: 'video',
      mimeType,
      disableLogs: true,
    });
  
    recorderRef.current.startRecording();
    setIsRecording(true);
    setMessage('Gravando... Olhe para a câmera e sorria.');
  
    monitorFaceDetection(); // Inicia monitoramento de rosto
  
    setTimeout(() => {
      if (isFaceDetectedRef.current) { // Verifica o ref em vez do estado
        stopRecording();
      } else {
        discardRecording();
      }
    }, 3000);
  };

  const stopRecording = async () => {
    if (!recorderRef.current) return;

    clearInterval(faceDetectionInterval.current); // Limpa o intervalo de detecção de rosto
    recorderRef.current.stopRecording(async () => {
      const blob = recorderRef.current.getBlob();
      await handleRecordingStop(blob);
    });
  };

  const discardRecording = () => {
    clearInterval(faceDetectionInterval.current); // Limpa o intervalo de detecção de rosto
    setMessage('Nenhum rosto detectado. Reiniciando...');
    setIsRecording(false);
    recorderRef.current?.reset();
    setTimeout(() => startCountdown(), 2000);
  };

  const monitorFaceDetection = () => {
    faceDetectionInterval.current = setInterval(async () => {
      if (!videoRef.current) return;
  
      const detections = await faceapi.detectSingleFace(
        videoRef.current,
        new faceapi.TinyFaceDetectorOptions({ inputSize: 160, scoreThreshold: 0.5 })
      );
      isFaceDetectedRef.current = !!detections; // Atualiza o valor do ref
      setIsFaceDetected(isFaceDetectedRef.current); // Atualiza o estado para fins de exibição
    }, 500);
  };

  const handleRecordingStop = async (blob) => {
    const formData = new FormData();
    const id_processo = localStorage.getItem('@data-prove-biometria:driverId');

    const fileName = blob.type.includes('mp4') ? 'video.mp4' : 'video.webm';
    formData.append('video', blob, fileName);
    formData.append('id_processo', id_processo);

    setMessage('Enviando vídeo...');
    setIsUploading(true);
    setIsVideoVisible(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE}/biometria/remota/facial`,
        formData
      );
      setMessage(response.data.message || 'Vídeo enviado com sucesso.');
      stopVideoStream();
      setIsFinished(true);
      setIsUploading(false);
      setIsVideoVisible(true);
      navigate('/sucesso_validacao');
    } catch (error) {
      const errorMessage = translateError(error.response?.data?.message);
      setMessage(errorMessage);
      setIsUploading(false);
      setIsVideoVisible(true);
      setTimeout(() => startCountdown(), 2000);
    }
  };

  const stopVideoStream = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  return (
    <>
      <div className="bodyValidRemota backgroundThemeCnh">
        <div className="header-validacao" style={{ marginBottom: '5px' }}>
          <img style={{ width: 100 }} className="logo" src={logo} alt="" />
        </div>

        <div style={{ textAlign: 'center', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          <Spinner animation="border" variant="light" />
          <h3 style={{ marginBottom: '0px', color: 'white', marginLeft: '10px' }} className="titleFaceApi">
            {countdown !== null ? `Gravação começará em ${countdown}s` : message}
          </h3>
        </div>

        <div
          className="app__video"
          style={{
            width: '100%',
            display: isVideoVisible ? 'flex' : 'none',
            justifyContent: 'center',
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            style={{
              borderRadius: '50%',
              border: '5px solid #4CAF50',
              width: '286px',
              height: '430px',
              objectFit: 'cover',
              overflow: 'hidden',
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            }}
          />
        </div>
        <h4 style={{ marginTop: '20px', color: 'white' }} className="titleFaceApi">
          Validação Facial
        </h4>
      </div>
    </>
  );
};

export default FaceId;
