import React from "react";

import PageNotFoundComponent from "./Shared/Page.Not.Found/Page404";
import SuccessPage from "./Modulo.Validacao.Remota/SuccessPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./default.style.css";
import "./login.style.css";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import { isAuthenticatedBiometria } from "./Services/Auth";
import ValidacaoBiometria from "./Modulo.Validacao.Remota/validacao-biometria";

function RequireAuth({ children }) {
  if (!isAuthenticatedBiometria()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    window.location.href = "https://dataprove.com.br/";
    return () => <></>;
  }

  return children;
}

export default function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/termosconsentimentomotorista/:hash"
          element={<ValidacaoBiometria />}
        />
        <Route
          exact
          path="/sucesso_validacao"
          element={
            /* <RequireAuth> */
            <SuccessPage />
            /* </RequireAuth> */
          }
        />
        <Route path="*" element={<PageNotFoundComponent />} />
      </Routes>
    </Router>
  );
}
