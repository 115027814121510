import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Button,
  Figure,
  Form,
  Spinner,
} from "react-bootstrap";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Api from "../Utils/AxiosBiometria";
import axios from "axios";
import SweetAlert from "../Utils/SweetAlert";
import Logo from "../Assets/images/Logos/logo_dataprove.png";
import "./styles.css";

import "react-accessible-accordion/dist/fancy-example.css";

const nominatimOpenStreetMaps = axios.create({
  baseURL: "https://nominatim.openstreetmap.org",
});

const phoneWhatsapp = process.env.REACT_APP_PHONE_WHATSAPP;

const TermosValidacao = ({
  hash,
  step,
  setStep,
  handleNextStep,
  getCoordinates,
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled,
  setLoadingButton,
  loadingButton
}) => {
  const [arrayPermissoes, setArrayPermissoes] = useState({
    armazenamento_foto_validacao_cnh: false,
    exibicao_foto_historico_pesquisas: false,
    uso_dados_informativos: false,
    aceite_flex_consulta: false,
    aceite_transportadora: false,
  });
  const [data, setData] = useState({});
  const [habilitaBotao, setHabilitaBotao] = useState(false);
  const [loading, setLoading] = useState(false); // true
  const [isMobile, setIsMobile] = useState(false);
  const [hasAccordionExpanded, setHasAccordionExpanded] = useState([]);

  const history = useNavigate();

  useEffect(() => {
    const $body = document.body;
    $body.style.setProperty("--theme-color", `#00955F`);
    $body.style.setProperty("--theme-color-active", `#00955F`);
    $body.style.setProperty("--theme-color-pagination", `#00955F`);

    $body.style.setProperty("--theme-color-border", `#00955F`);

    window.addEventListener("resize", handleResize);

    if (!isGeolocationAvailable) {
      SweetAlert.small({
        type: "error",
        title:
          "Erro - 6004, Este dispositivo não suporta serviços de Geolocalização!",
      });
    }

    getDados();
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  function getDados() {
    // setData({
    //   nome_mot: "GABRIELA DE ***ZA",
    //   empresa: "Flex Consulta",
    //   cnpj_empresa: "42.520.419/0001-14",
    //   email_dpo_empresa: "",
    //   solicitante: "Gabriela ***deira",
    //   tel_transp: "64996034144",
    //   celular_mot: "27999039212",
    //   etapas_concluidas:
    //     '{"criacao":{"success":true,"data":"2024-06-08T13:12:36.012Z"},"msg_whatsapp_step_1":{"success":true,"data":"2024-06-08T13:13:00.334Z"},"msg_whatsapp_step_4":{"success":true,"data":"2024-06-08T13:13:07.261Z"},"msg_whatsapp_step_5":{"success":true,"data":"2024-06-08T13:13:07.706Z"}}',
    //   data_validacao: "2024-06-08T13:12:00.000Z",
    //   logo_transp: null,
    //   cpf_mot: "148.***.***-02",
    // });
    Api.get("/acesso/motorista/termo/" + hash)
      .then(({ data }) => {
        if (data?.etapas_concluidas) {
          const map = new Map(
            Object.entries(JSON.parse(data?.etapas_concluidas))
          );

          let obj = JSON.parse(data?.etapas_concluidas);

          console.log(obj);

          if (obj?.coleta_biometria?.success) {
            SweetAlert.small({
              type: "error",
              title:
                "Erro - 6002, Processo de validação de biometria inválida!",
              timer: 3000,
              cb: () => {
                localStorage.removeItem("@data-prove-biometria:driverToken");
                localStorage.removeItem("@data-prove-biometria:driverId");
                window.location.href = "https://dataprove.com.br/";
                return;
              },
            });
          } else {
            console.log('data===>')
            console.log(data)
            console.log(map.size)
            if (map.size == 3 || map.size == 4 || map.size == 5) {
              setData(data);
              // timeLoc();
              setLoading(false);
            } else {
              const driverToken = localStorage.getItem(
                "@data-prove-biometria:driverToken"
              );
              const driverId = localStorage.getItem(
                "@data-prove-biometria:driverId"
              );

              if (driverToken && driverId) {
                setStep(1);
              }

              setLoading(false);
            }
          }
        } else {
          {/* Api.post("/antares", {
            message:
              "49690254, Erro ao obter as etapas do processo da biometria - LINHA 220 | index.jsx",
            level: "2",
            data: "sem retorno",
          }); */}

          SweetAlert.small({
            timer: 3000,
            type: "error",
            title: "Erro - 6000, Erro ao carregar o termo!",
            cb: () => {
              window.location.href = "https://dataprove.com.br/";
              return;
            },
          });
        }
      })
      .catch((error) => {
        {/* Api.post("/antares", {
          message:
            "4a424911, Erro ao acessar termo de aceite da validação de biometria - LINHA 232 | index.jsx",
          level: "2",
          data: JSON.stringify(error),
        }); */}

        history("/error");

        SweetAlert.small({ type: "error", title: `Erro - 6001,  ${error}` });
        setLoading(false);
        setLoadingButton(false);
      });
  }

  const handleCancel = () => {
    SweetAlert.default({
      title: "Cancelar?",
      text: "Deseja cancelar a solicitação?",
      icon: "question",
      showBtnCancel: true,
      confirmButtonText: "Sim confirmo!",
      callback: () => {
        window.location.href = "https://dataprove.com.br/";
        return;
      },
    });
  };

  function login() {
    setLoadingButton(true);
  
    nominatimOpenStreetMaps
      .get(`/reverse?lat=${coords?.latitude}&lon=${coords?.longitude}&format=json`)
      .then(({ data }) => {
        // Caso a requisição ao Nominatim seja bem-sucedida
        const geolocalizacao = {
          latitude: coords?.latitude || "",
          longitude: coords?.longitude || "",
          endereço: data?.display_name || "",
          cidade:
            data?.address?.city ||
            data?.address?.town ||
            data?.address?.village ||
            "",
          uf: data?.address?.state || "",
        };
  
        const coordinates = {
          latitude: coords?.latitude || "",
          longitude: coords?.longitude || "",
          endereco: data?.display_name || "",
        };
  
        getCoordinates(coordinates);
  
        Api.post("/acesso/motorista/termo", {
          hash_token: hash || "",
          aceito_termo: true,
          geo_localizacao: {
            endereço: geolocalizacao.endereço,
            latitude: geolocalizacao.latitude,
            longitude: geolocalizacao.longitude,
            cidade: geolocalizacao.cidade,
            uf: geolocalizacao.uf,
          },
        }).catch((error) => {
          setLoadingButton(false);
  
          // Log ou tratamento de erro pode ser ativado se necessário:
          /*
          Api.post("/antares", {
            message: "Erro ao aceitar o termo da validação da biometria - Linha 308",
            level: "2",
            data: JSON.stringify(error),
          });
          */
        });
      })
      .catch((err) => {
        // Caso a requisição ao Nominatim falhe
        console.error("Erro ao obter dados do Nominatim:", err);
  
        const geolocalizacao = {
          latitude: coords?.latitude || "",
          longitude: coords?.longitude || "",
          endereço: "", // Strings vazias porque não há dados do Nominatim
          cidade: "",
          uf: "",
        };
  
        const coordinates = {
          latitude: coords?.latitude || "",
          longitude: coords?.longitude || "",
          endereco: "", // Strings vazias porque não há dados do Nominatim
        };
  
        getCoordinates(coordinates);
  
        Api.post("/acesso/motorista/termo", {
          hash_token: hash || "",
          aceito_termo: true,
          geo_localizacao: {
            endereço: geolocalizacao.endereço,
            latitude: geolocalizacao.latitude,
            longitude: geolocalizacao.longitude,
            cidade: geolocalizacao.cidade,
            uf: geolocalizacao.uf,
          },
        }).catch((error) => {
          setLoadingButton(false);
  
          // Log ou tratamento de erro pode ser ativado se necessário:
          /*
          Api.post("/antares", {
            message: "Erro ao aceitar o termo da validação da biometria - Linha 308",
            level: "2",
            data: JSON.stringify(error),
          });
          */
        });
      });
  }
  

  const handleConfirm = () => {
    //getPosition(); NAO
    // handleNextStep();

    if (isGeolocationEnabled) {
      login();
    } else {
      SweetAlert.small({
        type: "error",
        title:
          "Erro - 6003, Aceite as permissões de localização para continuar!",
      });
    }
  };

  useEffect(() => {
    if (
      arrayPermissoes.armazenamento_foto_validacao_cnh &&
      arrayPermissoes.exibicao_foto_historico_pesquisas &&
      arrayPermissoes.uso_dados_informativos &&
      arrayPermissoes.aceite_flex_consulta &&
      arrayPermissoes.aceite_transportadora
    ) {
      setHabilitaBotao(true);
    }
    if (
      !arrayPermissoes.armazenamento_foto_validacao_cnh ||
      !arrayPermissoes.exibicao_foto_historico_pesquisas ||
      !arrayPermissoes.uso_dados_informativos ||
      !arrayPermissoes.aceite_flex_consulta ||
      !arrayPermissoes.aceite_transportadora
    ) {
      setHabilitaBotao(false);
    }
  }, [arrayPermissoes]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <Container className="validationTerms-page mt-2">
      <Row
        className="m-0 d-flex flex-column"
        style={{ justifyContent: "space-between" }}
      >
        <div style={{ marginLeft: "0px",marginBottom:'30px' }}>
          <Figure>
            <Figure.Image width={75} height={"auto"} src={Logo} />
            <Figure.Caption hidden>Logo da Data Prove</Figure.Caption>
          </Figure>
        </div>

        <Col className="d-flex flex-column justify-content-center text-center col-title">
          <h1
            className="titleTermoAll"
            style={{ fontSize: "20px", color: "#363636", fontWeight: "bold" }}
          >
            TERMO DE CONSENTIMENTO
          </h1>
          <h4
            className="titleTermo"
            style={{ fontSize: "18px", color: "#707070" }}
          >
            Biometria facial
          </h4>
        </Col>
      </Row>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Spinner animation="border" role="status" />
        </div>
      ) : (
        <>
          <Container className="px-3 mt-4 mb-4">
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "1rem",
                border: "1px solid #CFCFCF",
                borderRadius: "6px",
                padding: "8px 0px",
              }}
            >
              <Col>
                <h5
                  className="empresaTermoText"
                  style={{ marginBottom: "3px" }}
                >
                  Empresa Solicitante: {data?.empresa}
                </h5>
                <h5
                  className="empresaTermoText"
                  style={{ marginBottom: "3px" }}
                >
                  CNPJ: {data?.cnpj_empresa}
                </h5>
              </Col>
            </Row>

            <Row
              style={{
                border: "1px solid #CFCFCF",
                borderRadius: "6px",
                padding: "8px 20px 0px",
              }}
            >
              <Row
                style={{
                  overflow: "scroll",
                }}
                className="mb-4 termoText"
              >
                <Col>
                  <Row>
                    <Col>
                      <div style={{ marginTop: "5px" }}>
                        <p style={{ textAlign: "justify" }}>
                          Eu, {data?.nome_mot}, inscrito no CPF nº{" "}
                          {data?.cpf_mot}, declaro que fui orientado(a) de forma
                          clara sobre o tratamento de Dados Pessoais pelo Dataprove (DATAPROVE DESENVOLVIMENTO DE SOFTWARE LTDA, inscrito
                          no CNPJ 55.227.077/0001-90), conforme as disposições
                          abaixo:
                        </p>
                      </div>

                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          1. Autorização
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Em observância à Lei nº. 13.709/1833 – Lei Geral de
                          Proteção de Dados Pessoais e demais normativas
                          aplicáveis sobre proteção de Dados Pessoais,
                          manifesto-me de forma informada, livre, expressa e
                          consciente, no sentido de autorizar o DATAPROVE a
                          realizar o tratamento de meus Dados Pessoais para as
                          finalidades e de acordo com as condições aqui
                          estabelecidas.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          2. Finalidades do tratamento
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Os meus Dados Pessoais poderão ser utilizados pelo
                          DATAPROVE para:
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.1 Cumprir as obrigações contratuais, legais e
                          regulatórias do SISTEMA DATAPROVE, em razão de suas
                          atividades;
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.2. Execução de seus programas e prestação de
                          serviços;
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.3. Oferecer produtos e serviços que sejam do meu
                          interesse;
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.4. Compartilhar com os clientes Dataprove, histórico de
                          viagens realizadas junto a transportadora para qual
                          houve prestação de serviço que é cliente Dataprove.
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.5. Realizar a comunicação oficial pelo SISTEMA DATAPROVE
                          ou por seus prestadores de serviço, por meio de canais
                          de comunicação (telefone, e-mail, SMS e WhatsApp);
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.6. Armazenar a foto coletada durante a validação de
                          biometria facial para fins de comprovação e histórico
                          de consulta realizada garantindo a veracidade da
                          consulta;
                        </p>
                        <p
                          style={{
                            textAlign: "justify",
                            paddingLeft: 10,
                            marginBottom: "-1px",
                          }}
                        >
                          2.7. Armazenar meu número de telefone, utilizado
                          durante a validação de biometria facial para garantir
                          a veracidade da consulta;
                        </p>
                        <p style={{ textAlign: "justify", paddingLeft: 10 }}>
                          2.8. Armazenar número de registro e categoria da CNH,
                          utilizado para validação de biometria facial;
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          Estou ciente que a DATAPROVE poderá compartilhar
                          os meus Dados Pessoais com seus parceiros e demais
                          prestadores de serviços, restringindo-se às funções e
                          atividades por cada um desempenhadas e em aderência às
                          finalidades acima estabelecidas.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          3. Confidencialidade
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Estou ciente do compromisso assumido pela Dataprove de tratar os meus Dados Pessoais de forma
                          sigilosa e confidencial, mantendo-os em ambiente
                          seguro e não sendo utilizados para qualquer fim que
                          não os descritos acima.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          4. Governança e Segurança dos Dados
                        </h4>
                        <p style={{ textAlign: "justify", paddingLeft: "5px" }}>
                          4.1. A CONTRATADA compromete-se a adotar medidas,
                          ferramentas e tecnologias necessárias para garantir a
                          segurança dos dados e cumprir com suas obrigações,
                          sempre considerando o estado da técnica disponível e
                          as bases contratuais originais.
                        </p>
                        <p style={{ textAlign: "justify", paddingLeft: "5px" }}>
                          4.2. A CONTRATADA manterá registro das operações de
                          tratamento dos dados pessoais que realizar,
                          implementando medidas técnicas e organizacionais
                          necessárias para proteger os dados contra a
                          destruição, acidental ou ilícita, a perda, a
                          alteração, a comunicação ou difusão ou o acesso não
                          autorizado, além de garantir que os ambientes (seja
                          ele físico ou lógico) utilizados por ela para o
                          tratamento de dados pessoais sejam estruturados de
                          forma a atender os requisitos de segurança, previstos
                          em boas práticas e de governança e aos princípios
                          gerais previstos em Lei e às demais normas
                          regulamentares aplicáveis.
                        </p>
                        <p style={{ textAlign: "justify", paddingLeft: "5px" }}>
                          4.3. Em que pese os melhores esforços da CONTRATADA em
                          proteger as informações, é dever de cada CONTRATANTE a
                          responsabilidade de garantir e assegurar que seus
                          computadores se encontrem adequadamente protegido
                          contra softwares nocivos, como vírus, spywares,
                          adwares, acesso remoto não autorizado, dentre outras
                          atividades e programas maliciosos no meio digital.
                        </p>
                        <p style={{ textAlign: "justify", paddingLeft: "5px" }}>
                          4.4. A CONTRATANTE declara estar ciente de que é
                          responsável pela adoção de medidas de segurança
                          adequadas, como, por exemplo, a configuração segura de
                          seu navegador, utilização de programa antivírus
                          atualizado, firewall, IPS, WAF (Web Application
                          Firewall), atualizar seus softwares dos servidores e
                          nos computadores, criar utilizar programas
                          desenvolvidos com técnicas que garantam segurança, não
                          utilização de software de origem ilegal ou duvidosa,
                          utilizar senhas seguras e complexas, e demais melhores
                          práticas de segurança, dentre outros, sem os quais o
                          risco de os dados pessoais e senhas serem acessados
                          por terceiros, sem autorização para tal, é
                          consideravelmente maior.
                        </p>
                        <p style={{ textAlign: "justify", paddingLeft: "5px" }}>
                          4.5. Diante disso, a CONTRATANTE exime expressamente a
                          CONTRATADA de quaisquer responsabilidades por
                          eventuais incidentes de vazamento de dados e dos danos
                          e/ou prejuízos decorrentes de eventual invasão no
                          Portal de Acesso utilizado pela CONTRATANTE para
                          acessar os servidores da CONTRATADA, bem como de
                          demais falhas relacionadas à segurança dos dados
                          coletados, salvo na hipótese de dolo ou culpa da
                          CONTRATADA.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          5. Revogação
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Estou ciente que, a qualquer tempo, posso retirar o
                          consentimento ora fornecido, hipótese em que as
                          atividades desenvolvidas pela DATAPROVE, no âmbito
                          de nossa relação, poderão restar prejudicadas.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          Declaro e concordo que os meus Dados Pessoais poderão
                          ser armazenados em até 5 anos, mesmo após o término do
                          tratamento – inclusive após a revogação do
                          consentimento –, (i) para cumprimento de obrigação
                          legal ou regulatória pela DATAPROVE ou (ii) desde
                          que tornados anônimos.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          6. Comprometimento a LGPD
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Por meio do presente instrumento, a DATAPROVE,
                          seja na condição de controladora ou operadora de
                          dados, em decorrência da relação contratual havida com
                          a CONTRATANTE, reitera seu compromisso com a estrita
                          observância dos princípios norteadores da Lei Geral de
                          Proteção de Dados Pessoais (Lei nº. 13.709/18),
                          reafirmando tudo o quanto exposto em sua Política de
                          Privacidade e Proteção de Dados, à qual a CONTRATANTE
                          declara ter tido acesso e concordar com o seu inteiro
                          teor.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          7. Canal de Atendimento
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Estou ciente que posso utilizar o canal de atendimento
                          à LGPD da DATAPROVE, por meio do endereço
                          dpo@flexconsulta.com.br, para tirar dúvidas e/ou
                          realizar solicitações relacionadas ao tratamento dos
                          meus Dados Pessoais.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                          Por fim, declaro ter lido e ter sido suficientemente
                          informado sobre o conteúdo deste Termo e concordo com
                          o tratamento dos meus Dados Pessoais aqui descrito de
                          forma livre e esclarecida, em observância à Lei Geral
                          de Proteção de Dados e às demais normativas sobre
                          proteção de Dados Pessoais aplicáveis.
                        </p>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: "14px",
                            paddingLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          8. Canal de Comunicação
                        </h4>
                        <p style={{ textAlign: "justify" }}>
                          Manifesto-me de forma informada, livre, expressa e
                          consciente, no sentido de autorizar o SISTEMA Dataprove a realizar contato comigo através dos
                          seguintes canais:
                        </p>
                        {data?.email_dpo_empresa && (
                          <p
                            style={{
                              textAlign: "justify",
                              marginBottom: "3px",
                            }}
                          >
                            {data?.email_dpo_empresa};
                          </p>
                        )}
                        <p style={{ textAlign: "justify" }}>
                          App DATAPROVE.
                        </p>
                      </div>

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <label style={{ fontSize: "13px", color: "black" }}>
                          {moment(new Date()).format("ll")}
                        </label>
                      </div>
                    </Col>
                  </Row>
                  {}
                </Col>
              </Row>
            </Row>

            <Row className="mt-3 mb-4">
              <Col>
                <Form.Check
                  onChange={(e) => {
                    if (e.target.checked) {
                      setArrayPermissoes({
                        armazenamento_foto_validacao_cnh: true,
                        exibicao_foto_historico_pesquisas: true,
                        uso_dados_informativos: true,
                        aceite_flex_consulta: true,
                        aceite_transportadora: true,
                      });
                    } else {
                      setArrayPermissoes({
                        armazenamento_foto_validacao_cnh: false,
                        exibicao_foto_historico_pesquisas: false,
                        uso_dados_informativos: false,
                        aceite_flex_consulta: false,
                        aceite_transportadora: false,
                      });
                    }
                  }}
                  type="checkbox"
                  className="inputTermoG"
                  id={`default-00`}
                  label={"Aceitar Todos"}
                  style={{ marginBottom: 10 }}
                  checked={
                    arrayPermissoes.armazenamento_foto_validacao_cnh &&
                    arrayPermissoes.exibicao_foto_historico_pesquisas &&
                    arrayPermissoes.uso_dados_informativos &&
                    arrayPermissoes.aceite_flex_consulta &&
                    arrayPermissoes.aceite_transportadora
                  }
                />

                <Accordion
                  className="arcodeonTermo"
                  allowZeroExpanded
                  onChange={(e) => setHasAccordionExpanded(e)}
                >
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Mais informações do termo de aceite
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Form.Check
                        onChange={(e) => {
                          if (e.target.checked) {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                armazenamento_foto_validacao_cnh: true,
                              };
                            });
                          } else {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                armazenamento_foto_validacao_cnh: false,
                              };
                            });
                          }
                        }}
                        type="checkbox"
                        className="inputTermoG"
                        id={`default-01`}
                        label={"Armazenar foto da Validação do CNH"}
                        style={{ marginBottom: 10 }}
                        defaultChecked={
                          arrayPermissoes.armazenamento_foto_validacao_cnh
                        }
                        checked={
                          arrayPermissoes.armazenamento_foto_validacao_cnh
                        }
                      />
                      <Form.Check
                        onChange={(e) => {
                          if (e.target.checked) {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                exibicao_foto_historico_pesquisas: true,
                              };
                            });
                          } else {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                exibicao_foto_historico_pesquisas: false,
                              };
                            });
                          }
                        }}
                        type="checkbox"
                        className="inputTermoG"
                        id={`default-02`}
                        label={
                          "Exibição foto na pesquisa de viagens realizadas"
                        }
                        style={{ marginBottom: 10 }}
                        defaultChecked={
                          arrayPermissoes.exibicao_foto_historico_pesquisas
                        }
                        checked={
                          arrayPermissoes.exibicao_foto_historico_pesquisas
                        }
                      />
                      <Form.Check
                        style={{ marginBottom: 10 }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                uso_dados_informativos: true,
                              };
                            });
                          } else {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                uso_dados_informativos: false,
                              };
                            });
                          }
                        }}
                        type="checkbox"
                        className="inputTermoG"
                        id={`default-03`}
                        label={
                          "Coleta de dados pessoais (Nome, CPF, Celular e N° de CNH)"
                        }
                        defaultChecked={arrayPermissoes.uso_dados_informativos}
                        checked={arrayPermissoes.uso_dados_informativos}
                      />
                      <Form.Check
                        style={{ marginBottom: 10 }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setArrayPermissoes((update) => {
                              return { ...update, aceite_flex_consulta: true };
                            });
                          } else {
                            setArrayPermissoes((update) => {
                              return { ...update, aceite_flex_consulta: false };
                            });
                          }
                        }}
                        type="checkbox"
                        className="inputTermoG"
                        id={`default-04`}
                        label={"Li e concordo com os termos da DATAPROVE"}
                        defaultChecked={arrayPermissoes.aceite_flex_consulta}
                        checked={arrayPermissoes.aceite_flex_consulta}
                      />
                      <Form.Check
                        onChange={(e) => {
                          if (e.target.checked) {
                            setArrayPermissoes((update) => {
                              return { ...update, aceite_transportadora: true };
                            });
                          } else {
                            setArrayPermissoes((update) => {
                              return {
                                ...update,
                                aceite_transportadora: false,
                              };
                            });
                          }
                        }}
                        type="checkbox"
                        className="inputTermoG"
                        id={`default-05`}
                        label={`Li e concordo com os termos - ${data?.empresa}`}
                        defaultChecked={arrayPermissoes.aceite_transportadora}
                        checked={arrayPermissoes.aceite_transportadora}
                      />
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </Col>
            </Row>
          </Container>

          <Row
            className="terms-buttons px-3 mb-5"
            style={
              hasAccordionExpanded?.length
                ? { marginTop: "18rem" }
                : { marginTop: "0" }
            }
          >
            <Col
              style={{ marginBottom: "15px" }}
              className="d-flex d-md-none"
              md={2}
            >
              <Button
                disabled={
                  (!habilitaBotao || loadingButton) && !isGeolocationEnabled
                }
                onClick={handleConfirm}
              >
                {" "}
                {(loadingButton && (
                  <>
                    <Spinner animation="grow" role="status" size="sm">
                      <span className="sr-only">Loading...</span>
                    </Spinner>{" "}
                    Aguarde...
                  </>
                )) ||
                  "Confirmar"}
              </Button>
            </Col>
            <Col
              style={{ marginRight: "15px" }}
              className="d-none d-md-flex pb-md-0 p-md-0"
            >
            
              <Button
                disabled={
                  (!habilitaBotao || loadingButton || !isGeolocationEnabled)
                }
                onClick={handleConfirm}
              >
                {" "}
                {(loadingButton && (
                  <>
                    <Spinner animation="grow" role="status" size="sm">
                      <span className="sr-only">Loading...</span>
                    </Spinner>{" "}
                    Aguarde...
                  </>
                )) ||
                  "Confirmar"}
              </Button>
            </Col>
            <Col className="mb-3 mb-md-0 p-md-0">
              <Button
                disabled={loadingButton}
                variant="secondary"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default TermosValidacao;
