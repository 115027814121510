import { Button, Col, Row } from "react-bootstrap";
import logo from "../../Assets/images/Logos/logo_dataprove.png";
import imagemPasso01 from "../../Assets/images/Icons/character_img.png";
import acceptIcon from "../../Assets/images/Icons/accept.png";
import Api from "../../Utils/AxiosBiometria";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SweetAlert from "../../Utils/SweetAlert";
import "./styles.css";
import { FaSquareFull } from "react-icons/fa6";

const PaginaPassoAPassoValidacao = ({ handleNextStep }) => {
  const { hash } = useParams();
  const history = useNavigate();

  useEffect(() => {
    getDados();
  }, []);

  function getDados() {
    Api.get("/acesso/motorista/termo/" + hash)
      .then(({ data }) => {
        if (data?.etapas_concluidas) {
          let obj = JSON.parse(data?.etapas_concluidas);
          if (obj?.coleta_biometria?.success) {
            {/* Api.post("/antares", {
              message:
                "0a796354, O processo consta como concluído  - LINHA 32 | index.jsx",
              level: "2",
              data: JSON.stringify(obj),
            }); */}
            SweetAlert.small({
              type: "error",
              title: "Erro 4000 - Processo de validação de biometria inválida!",
              timer: 3000,
              cb: () => {
                localStorage.removeItem("@data-prove-biometria:driverToken");
                localStorage.removeItem("@data-prove-biometria:driverId");
                window.location.href = "https://dataprove.com.br";
                return;
              },
            });
          }
        } else {
          {/* Api.post("/antares", {
            message:
              "eca03afb, Erro ao verificar as etapas concluidas na validaçao de biometria  - LINHA 55 | index.jsx",
            level: "2",
            data: "vazio",
          }); */}
          SweetAlert.small({
            timer: 3000,
            type: "error",
            title: "Erro 4001 - Erro ao carregar o termo!",
            cb: () => {
              window.location.href = "https://dataprove.com.br";
              return;
            },
          });
        }
      })
      .catch((error) => {
        {/*Api.post("/antares", {
          message:
            "0a796354, Erro ao acessar o termo da validaçao de biometria  - LINHA 75 | index.jsx",
          level: "2",
          data: JSON.stringify(error),
        }); */}
        history("/error");
        SweetAlert.small({ type: "error", title: `Error 501 - ${error}` });
      });
  }

  const handleCancel = () => {
    SweetAlert.default({
      title: "Cancelar?",
      text: "Deseja cancelar a solicitação?",
      icon: "question",
      showBtnCancel: true,
      confirmButtonText: "Sim confirmo!",
      callback: () => {
        window.location.href = "https://dataprove.com.br/";
        return;
      },
    });
  };

  return (
    <div className="stepsPage">
      <header>
        <img src={logo} alt="Logotipo data prove" />
      </header>

      <div className="stepsContent">
        <img src={imagemPasso01} alt="Uma mão segurando um smartphone" />

        <h2 className="stepsImgTitle">
          Com essa foto faremos a validação da sua identidade
        </h2>

        <div className="stepsToFollow">
          <div className="step">
            <div>
              <FaSquareFull size={8} />
            </div>
            <p>
              Prefira um local bem iluminado, de preferência com luz natural.
            </p>
          </div>

          <div className="step">
            <div>
              <FaSquareFull size={8} />
            </div>
            <p>
              Certifique-se de que seu rosto está totalmente visível e sem
              acessórios.
            </p>
          </div>

          <div className="step">
            <div>
              <FaSquareFull size={8} />
            </div>
            <p>
              Mantenha a câmera na altura dos olhos e evite movimentar-se
              durante a captura.
            </p>
          </div>
        </div>

        <Row className="m-0 w-100 d-flex flex-column">
          <Col className="p-0 mb-3">
            <Button
              variant="primary"
              className="stepsConfirmBtn"
              onClick={handleNextStep}
            >
              Começar
            </Button>
          </Col>

          <Col className="p-0">
            <Button
              variant="secondary"
              className="stepsCancelBtn"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PaginaPassoAPassoValidacao;
